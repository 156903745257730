import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from '../../../i18next';
import { isMobile } from 'mobile-device-detect';
import ModalStep from '../../common/ModalStep';
import { FRONT_END_URL, ARABIC, GOOGLE_CLIENT_ID, GENERIC_API_KEY } from '../../../utils/Constants';
import { startOrderScreenPath, openStartOrderScreen } from '../../../actions';
import FacebookLogoSVG from '../../../pwa-app/components/common/svgComponents/FacebookLogoSVG';
import AppleLogoSVG from '../../../pwa-app/components/common/svgComponents/AppleLogoSVG';
import GoogleLogoSVG from '../../../pwa-app/components/common/svgComponents/GoogleLogoSVG';
import { decryptData, hashKey } from '../../../utils/CommonUtils';

class SocialSignInResponse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            socialProviderName: '',
            isCheckoutLogin: false,
            userName: '',
            userEmail: '',
            userPicture: ''
        };

        this.socialSignInConfirm = this.socialSignInConfirm.bind(this);
    }

    goBack() {
        if (this.props.isCheckoutLogin) {
            const path = '/menu-screen';
            this.props.openStartOrderScreen(path);
        } else {
            this.props.history.goBack();
        }
    }

    componentWillMount() {
        this.applyTheme();
    }

    componentDidMount() {
        document.getElementsByClassName('modal-header')[0].remove();

        const isMobileView = window.outerWidth <= 800;
        if (isMobileView) {
            document.getElementsByClassName('modal-body')[0].style.minHeight = '480px';
        }

        if (this.props.newSocialLoginInfo) {
          let providerName = '';
          let userName = '';
          let userEmail = '';
          let userPicture = '';
          let isCheckoutLoginBool = false;
        
          if (this.props.newSocialLoginInfo.providerName) {
            providerName = this.props.newSocialLoginInfo.providerName;
          }

          if (this.props.newSocialLoginInfo.userFirstName) {
            userName = this.props.newSocialLoginInfo.userFirstName;
          }

          if (this.props.newSocialLoginInfo.userEmail) {
            userEmail = this.props.newSocialLoginInfo.userEmail;
          }

          if (this.props.newSocialLoginInfo.userPicture) {
            userPicture = this.props.newSocialLoginInfo.userPicture;
          }

          if (this.props.newSocialLoginInfo.isCheckoutLogin) {
              isCheckoutLoginBool = true;
          }

          this.setState({
              socialProviderName: providerName,
              isCheckoutLogin: isCheckoutLoginBool,
              userName: userName,
              userEmail: userEmail,
              userPicture: userPicture
          });
        }
    }

    socialSignInConfirm() {
        if ((this.state.socialProviderName === "google" && this.props.googleUserData) || (this.state.socialProviderName === "facebook" && this.props.facebookUserData) || (this.state.socialProviderName === "apple" && this.props.appleUserData)) {
            if (this.state.isCheckoutLogin) {
              const path = '/menu-screen/social-credentials';
              this.props.startOrderScreenPath(path);
            } else {
              this.props.history.push('/start-order/social-credentials');
            }
        }
    }

    openTermsAndConditionsPage() {
        let termsAndConditionsPage = window.open('https://menuu.com/terms-of-service', '_blank');
        termsAndConditionsPage.focus();
    }

    renderModalHeader() {
        const { imageStyle, imageStyleRTL, loginTextStyle, loginTextStyleRTL } = styles;
        const { type, primaryColor, secondaryColor, buttonOutlineColor } = this.props.customerThemes.selectedTheme;
        const { socialProviderName } = this.state;
        const isArabic = this.props.language === ARABIC;

        let isHidden = false;
        if (this.props.hideBackButton) {
            isHidden = true;
        }

        const socialHeaderStyle = {
            borderBottomStyle: 'dotted',
            borderBottomWidth: '1px',
            borderBottomColor: buttonOutlineColor
        }

        // Render the close button for start order modal on this screen when opening it from menuScreen
        let closeButtonShowClass = '';
        if (this.props.isOrderDone) closeButtonShowClass = ' from-menu-screen';

        return (
            <Fragment>
                <div className='start-order-header-container' style={socialHeaderStyle}>
                    <Button className="start-order-custom-back-button" disabled={isHidden} onClick={() => this.goBack()} style={{ opacity: (isHidden) ? 0 : 1 }}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    <div style={{ margin: '0 auto', textAlign: 'center', display: 'flex', alignItems: 'center', fontSize: 18, fontWeight: 300 }}>
                        {(() => {
                            if (socialProviderName === "google") {
                                return (
                                    <Fragment>
                                      <GoogleLogoSVG width={20} height={22} style={isArabic ? imageStyleRTL : imageStyle} />
                                      <span style={isArabic ? loginTextStyleRTL : loginTextStyle}>{i18n.t('screens:userCredentialsScreen.signUp')}</span>
                                    </Fragment>
                                );
                            } else if (socialProviderName === "facebook") {
                                return (
                                    <Fragment>
                                        <FacebookLogoSVG width={20} height={20} fill={'#3B579D'} style={isArabic ? imageStyleRTL : imageStyle} />
                                        <span style={isArabic ? loginTextStyleRTL : loginTextStyle}>{i18n.t('screens:userCredentialsScreen.signUp')}</span>                            	
                                    </Fragment>
                                );
                            } else if (socialProviderName === "apple") {
                                return (
                                    <Fragment>
                                        <AppleLogoSVG width={26} height={26} style={isArabic ? imageStyleRTL : imageStyle} />
                                        <span style={isArabic ? loginTextStyleRTL : loginTextStyle}>{i18n.t('screens:userCredentialsScreen.signUp')}</span>                            	
                                    </Fragment>
                                );
                            }
                        })()}
                    </div>

                    <div style={{ width: 90, textAlign: isArabic ? 'left' : 'right' }}>
                        <Button className={`start-order-custom-close-button ${closeButtonShowClass}`} onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderModalBody() {

        const { customBodyInnerContent, subHeading, footerColTwo, subMsg, termsAndConditions } = styles;
        const { primaryColor, primaryFontColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const { socialProviderName, userName, userEmail, userPicture } = this.state;

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const isArabic = this.props.language === ARABIC;

        let userFirstName = '';
        if (userName) {
          userFirstName = userName;
        } else if (userEmail) {
           userFirstName = userEmail;       	
        }

        const brandNameStyle = {
            fontWeight,
            color: primaryFontColor,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <React.Fragment>
                {this.renderModalHeader()}
                <div className='modal-body-custom' style={{ padding: '0 0 30px 0', margin: 0 }}>
                    <Col sm={12} style={{ margin: '25px 0px 10px 0px' }}>
                        {
                          this.props.logoImagePath ?
                              <img src={`${FRONT_END_URL}/${this.props.logoImagePath}`} style={{ maxWidth: 160 }} /> :
                              <h3 style={brandNameStyle}>{this.props.brand.name}</h3>
                        }
                    </Col>
                    <Col xs={12} className='modal-custom-body-inner-content' style={customBodyInnerContent}>
                        <Col xs={12} md={12} style={subHeading}>
                          <div><span style={{ fontWeight: 700 }}>{this.props.brand.name}</span> {i18n.t('screens:loginTypesScreen.willRecieve')}:</div>
                          <div>{i18n.t('screens:loginTypesScreen.yourNameEmail')}</div>
                        </Col>
                    </Col>

                    <Col sm={{ size: 10, offset: 1 }} style={{ margin: '0 auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button className='hoverEffect' color="success" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: 50,
                                alignItems: 'center',
                                padding: '0px 1px'
                            }} onClick={this.socialSignInConfirm}>
                                {(() => {
                                    if (socialProviderName && userFirstName) {
                                        return (
                                            <div>
                                                <span style={{ padding: '0 15px' }}>
                                                    {i18n.t('screens:loginTypesScreen.continueAs')}
                                                    <span style={{ fontWeight: 400 }}> {userFirstName}</span>
                                                </span>
                                                {userPicture &&
                                                    <img style={{ height: '46px' }} src={userPicture} />
                                                }
                                            </div>
                                        );
                                    }
                                })()}
                          </Button>
                        </div>
                    </Col>
                </div>
            </React.Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, mainTextColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.termsAndConditions.color = primaryColor;
        customizedStyles.termsAndConditions.textDecoration = textDecoration;
        customizedStyles.subMsg.color = mainTextColor;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.loginTextStyle.color = primaryFontColor;
        customizedStyles.loginTextStyleRTL.color = primaryFontColor;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const startOrderStyles = isMobile ? { overflowY: 'auto', overflowX: 'hidden', height: window.innerHeight } : {}

        return (
            <AvForm className="start-order phoneNumberScreen" style={startOrderStyles}>
                <ModalStep
                    onToggle={this.props.toggle}
                    renderBody={this.renderModalBody()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        logoImagePath: state.orderTypes.logoImagePath,
        brand: state.brand,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language,
        googleUserData: state.customer.googleUserData,
        facebookUserData: state.customer.facebookUserData,
        appleUserData: state.customer.appleUserData,
        newSocialLoginInfo: state.customer.newSocialLoginInfo
    };
};

export default connect(mapStateToProps, { 
    startOrderScreenPath,    
    openStartOrderScreen 
})(SocialSignInResponse);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 16
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    loginTextStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle'
    },
    customBodyInnerContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '0.6 1 0'
    },
    subHeading: {
        fontSize: 16,
        color: '#939393',
        paddingBottom: 12,
        margin: 'auto'
    },
    footerColTwo: {
        textAlign: 'center'
    },
    subMsg: {
        fontSize: 14,
        color: '#9a9a9a',
        marginBottom: 5
    },
    termsAndConditions: {
        color: "#61B522",
        cursor: 'pointer'
    }
}