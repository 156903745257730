import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Col, ListGroup, InputGroup, InputGroupAddon, Input, Modal, Row } from 'reactstrap';
import i18n from '../../../i18next';
import { isIOS, isMobileOnly } from 'mobile-device-detect';
import promoCodesIcon from '../../../images/icons/icon-voucher.png';
import { getPriceWithCurrencySymbol, transformProductPrice, getCurrencySymbol, makeCookTimeCalculations, checkIfServiceFeeIsWrongAndAmend } from '../../../utils/CommonUtils';
import { checkCartProductsNutrients } from '../../../utils/ProductWizardUtils';
import { sendMessageToParentSite } from "../../../utils/CrossOriginUtils";
import {
    addOrSubstractItem,
    removeItemFromCart,
    increaseOrDecreaseTip,
    openStartOrderScreen,
    closeStartOrderScreen,
    placeOrder,
    getSelectedProduct,
    calculatePromotion,
    dispatchPromoCodeToStore,
    dispatchServiceFeeToStore,
    getUpsellProducts,
    availableTimeSlot,
    errorProcessingPayment,
    setMenuOrOrderHistoryScreen,
    saveLongestCookTime,
    togglePromotionsModal,
    toggleShoppingCartModal,
    clearGiftCardsState,
    applyGiftCard,
    clearGifCardsTotalDiscount,
    changeCodeManuallySet
} from '../../../actions/index';
import { transformShoppingCartProducts } from '../../../utils/DataTransformationUtils';
import { displayProductInformation, hidePromoCodesField, totalItemsCount } from '../../../utils/ShoppingCartUtils';
import { isCodeAppliedSuccessfully } from '../../../utils/promotions/como/ComoUtils';
import { pushDataToGoogleTagManager, getProductCategory } from '../../../utils/AnalyticsUtils';
import UserCredentialsScreen from '../../login/UserCredentialsScreen';
import ShoppingCartButton from '../shoppingCart/shoppingCartBtn/ShoppingCartButton';
import MenuuLogoSVG from '../../../pwa-app/components/common/svgComponents/MenuuLogoSVG';
import UpsellProducts from './UpsellProducts';
import ShoppingCartItem from './ShoppingCartItem';

import PhoneNumberScreen from '../../login/PhoneNumberScreen';
import LoginTypesScreen from '../../login/LoginTypesScreen';
import SocialUserCredentialsScreen from '../../login/SocialUserCredentialsScreen';
import SocialSignInResponse from '../../login/social/SocialSignInResponse';
import SmsConfirmationScreen from '../../login/SmsConfirmationScreen';
import TipScreen from './TipScreen';
import CommonModal from '../../common/CommonModal';
import NutritionsList from "../product/nutrition/NutritionsList";
import MinMaxOrderAmount from "./MinMaxOrderAmount";
import FreeProduct from './FreeProduct';
import { loadAllAvailableDiscounts } from "../../../actions";
import Discounts from "./Discounts";
import ComoPayment from '../../order/promotions/como/ComoPayment';
import { ARABIC } from "../../../utils/Constants";
import ForgottenEmailScreen from "../../login/ForgottenEmailScreen";
import ServiceFeeScreen from "./ServiceFeeScreen";
import MaterialIcon from 'material-icons-react';

class ShoppingCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currency: '',
            isSpecialInstructionsOpen: false,
            isStartScreenOpened: false,
            promoCode: null,
            landscape: false,
            stateAlreadyCopiedFromProps: false,
            selectedPaymentType: null,
            serviceFee: 0,
            serviceFeeObject: {},
            isTipModalOpen: false,
            isFreeProductModalOpen: false,
            isServiceFeeModalOpen: false
        }

        this.changeState = this.changeState.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.addOrSubstractItemQty = this.addOrSubstractItemQty.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    copyStateFromProps() {
        if (this.props.selectedRestaurant.currency !== undefined && this.props.selectedRestaurant.currency !== null
            && this.props.selectedRestaurant.currency !== '' && !this.state.stateAlreadyCopiedFromProps) {
            this.setState({
                currency: this.props.selectedRestaurant.currency,
                stateAlreadyCopiedFromProps: true
            });
        }
    }

    componentDidMount() {
        this.applyTheme();
        // Handles payment method selection on loaded content
        // without selecting payment method and going back and forth
        // results in NO service fee added leading to wrong total
        const { payment, loyalty, shoppingCart, promotionPartners, giftCards } = this.props;
        const isComoOnMenuScreen = promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2 && window.location.href.includes('menu-screen');
        const appliedGiftCards = giftCards.giftCards ? (giftCards.giftCards.appliedGiftCards && giftCards.giftCards.appliedGiftCards.length > 0) : null;

        // this if is for no service fee when we pay with vaucher if we dont need pay with vaucher use only setState
        if (shoppingCart.total !== 0 && appliedGiftCards) {
            this.setState({
                currency: this.props.selectedRestaurant.currency,
                selectedPaymentType: payment.selectedPaymentType,
                serviceFee: 0,
                serviceFeeObject: {},
                // Sets the promoCode to the loyalty one if any and then dispatches it to Redux Store
                promoCode: loyalty.oldestActiveCode && !this.props.storeLocalStorage.codeManuallySet ? loyalty.oldestActiveCode : (shoppingCart.promoCode && !isComoOnMenuScreen ? shoppingCart.promoCode : '')
            }, () => {
                this.props.dispatchServiceFeeToStore(this.state.serviceFeeObject);
                if (this.state.promoCode && !isComoOnMenuScreen) {

                    this.props.dispatchPromoCodeToStore(this.state.promoCode, undefined);
                }
            });
        } else if (shoppingCart.total !== 0) {
            this.setState({
                currency: this.props.selectedRestaurant.currency,
                selectedPaymentType: payment.selectedPaymentType,
                serviceFee: this.selectFeeAccordingToPayment('feeValue'),
                serviceFeeObject: this.selectFeeAccordingToPayment('feeObject'),
                // Sets the promoCode to the loyalty one if any and then dispatches it to Redux Store
                promoCode: loyalty.oldestActiveCode && !this.props.storeLocalStorage.codeManuallySet ? loyalty.oldestActiveCode : (shoppingCart.promoCode && !isComoOnMenuScreen ? shoppingCart.promoCode : '')
            }, () => {
                const serviceFeeObject = this.selectFeeAccordingToPayment('feeObject');
                this.props.dispatchServiceFeeToStore(serviceFeeObject);
                if (this.state.promoCode && !isComoOnMenuScreen) {

                    this.props.dispatchPromoCodeToStore(this.state.promoCode, undefined);
                }
            });
        }
        // Get all upsell products
        const restaurantId = this.props.menuPreviewMode ? new URLSearchParams(window.location.search).get('restaurantId') : this.props.selectedRestaurant.restaurantId;
        this.props.getUpsellProducts(restaurantId);
        this.props.calculatePromotion(this.props.selectedRestaurant.restaurantId);

        if (this.props.isCheckoutScreen && this.props.storeLocalStorage.isMenuOrOrderHistory) {
            this.toggleTipScreen()
        }

        if(!this.props.isCheckoutScreen) {
            this.setState({
                selectedPaymentType: payment.selectedPaymentType,
                serviceFee: 0,
                serviceFeeObject: {}
            }, () => {
                this.props.dispatchServiceFeeToStore(this.state.serviceFeeObject);
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                isStartScreenOpened: nextProps.isStartOrderScreenOpened
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { shoppingCart, payment, selectedRestaurant, promotionPartners, giftCards } = this.props;
        const { minimumOrderAmount } = this.props.selectedRestaurant;
        let currentMinimumOrderAmount = this.props.shoppingCart.appliedPromotions.length ? 1 : minimumOrderAmount;
        const isGiftCardsTotalDiscountZero = !this.props.giftCards.giftCardsTotalDiscount ? true : false;
        const appliedGiftCards = giftCards.giftCards ? (giftCards.giftCards.appliedGiftCards && giftCards.giftCards.appliedGiftCards.length > 0) : null;
        
        if (shoppingCart.promoCode !== null && this.state.promoCode === null && promotionPartners.promotionPartner.id !== 2) {

            this.setState({
                promoCode: shoppingCart.promoCode
            });
        }
        // this if is for no service fee when we pay with vaucher and if all gift cards are removed
        if (((payment.selectedPaymentType !== prevProps.payment.selectedPaymentType && shoppingCart.total !== 0 && this.props.selectedPaymentId)
            || ((prevProps.shoppingCart.total !== this.props.shoppingCart.total) && shoppingCart.total !== 0 && this.props.selectedPaymentId)
            || ((prevProps.isAppleGooglePaySelected !== this.props.isAppleGooglePaySelected) && shoppingCart.total !== 0) 
            || (((giftCards.giftCards !== prevProps.giftCards.giftCards) 
                && !appliedGiftCards)))) {
            
            if(appliedGiftCards) {
                this.setState({
                    selectedPaymentType: payment.selectedPaymentType,
                    serviceFee: 0,
                    serviceFeeObject: {}
                }, () => {
                    this.props.dispatchServiceFeeToStore(this.state.serviceFeeObject);
                });
            } else {
                this.setState({
                    selectedPaymentType: payment.selectedPaymentType,
                    serviceFee: this.selectFeeAccordingToPayment('feeValue'),
                    serviceFeeObject: this.selectFeeAccordingToPayment('feeObject')
                }, () => {
                    this.props.dispatchServiceFeeToStore(this.state.serviceFeeObject);
                });
            }
        }

        const isPrevTotalDifFromCurrTotal = prevProps.shoppingCart.total !== shoppingCart.total;
        const isCurrDiscountBiggerThanZero = shoppingCart.discount > 0;
        let totalMinusFee = shoppingCart.total - (shoppingCart.serviceFee ? shoppingCart.serviceFee : 0);
        totalMinusFee = Number((Number(Math.round(totalMinusFee + 'e2') + 'e-2')).toFixed(2));
        // Sets the loyalty promoCode
        if ((prevProps.loyalty.oldestActiveCode !== this.props.loyalty.oldestActiveCode && this.props.loyalty.oldestActiveCode !== undefined)
            || ((isPrevTotalDifFromCurrTotal || !shoppingCart.promoCode) && this.props.loyalty.oldestActiveCode && !this.props.storeLocalStorage.codeManuallySet)) {

            this.setState({
                promoCode: this.props.loyalty.oldestActiveCode
            }, () => this.props.dispatchPromoCodeToStore(this.props.loyalty.oldestActiveCode, undefined, true));
        }
        // when we do changes in shopping cart need to remove and add service fee and if gift card is applied
        if (shoppingCart.serviceFee !== this.state.serviceFee && shoppingCart.serviceFee && (shoppingCart.total !== 0 && isCurrDiscountBiggerThanZero) && this.props.selectedPaymentId && !appliedGiftCards) {
            this.setState({
                serviceFee: shoppingCart.serviceFee
            });
        } else if ((isPrevTotalDifFromCurrTotal && shoppingCart.total === 0 && isCurrDiscountBiggerThanZero)
            || (prevProps.selectedPaymentId !== this.props.selectedPaymentId && !this.props.selectedPaymentId)
            || (isPrevTotalDifFromCurrTotal && isCurrDiscountBiggerThanZero && (totalMinusFee < currentMinimumOrderAmount))
            || (shoppingCart.serviceFee && appliedGiftCards)
        ) {
            this.setState({
                serviceFee: 0,
                serviceFeeObject: {}
            }, () => {
                this.props.dispatchServiceFeeToStore(this.state.serviceFeeObject);
            });
        }

        if (prevProps.shoppingCart.orderProducts !== this.props.shoppingCart.orderProducts) {
            this.props.saveLongestCookTime(makeCookTimeCalculations(this.props.estimateOrderTime.additionalMoneyCookTimes, this.props.shoppingCart.orderProducts, this.props.shoppingCart.subtotal));

            if (!this.props.loyalty.oldestActiveCode || this.props.storeLocalStorage.codeManuallySet) {
                this.checkHasFreeProduct(prevProps.shoppingCart.orderProducts, this.props.shoppingCart.orderProducts);
            }
         }
        
        if ((giftCards.giftCards !== prevProps.giftCards.giftCards) && !appliedGiftCards) {
            // console.log('INSIDE DID UPD')
            this.props.clearGiftCardsState()
        }  
        //In case of empty shopping cart will remove applied giftcards
        if ((prevProps.shoppingCart.orderProducts !== this.props.shoppingCart.orderProducts)
            && shoppingCart.orderProducts.length === 0 
            && appliedGiftCards) {
            const emptyGiftCardsArray = [];
            const url = window.location.href;
            const isMenuScreen = url.includes('menu-screen');
            const isAppCartScreen = url.includes('order/cart');

            this.props.applyGiftCard(emptyGiftCardsArray, null);
            this.props.clearGiftCardsState();

            if (isMenuScreen || isAppCartScreen) {
                this.props.clearGifCardsTotalDiscount();
            }
        }

       
        if ((prevProps.shoppingCart.total !== this.props.shoppingCart.total)
            && (shoppingCart.orderProducts.length > 0 ) 
            && (appliedGiftCards)) {
                this.props.applyGiftCard(giftCards.giftCards.appliedGiftCards, null);
            
        }
        // check for available discounts when change time is pressed
        // if (this.props.estimateOrderTime.wantedTime !== prevProps.estimateOrderTime.wantedTime) {
        //     this.props.loadAllAvailableDiscounts(selectedRestaurant.restaurantId);
        // }

        if (this.props.customerThemes.selectedTheme.type !== prevProps.customerThemes.selectedTheme.type
            && window.location.href.includes('previewMode')) {
            this.applyTheme();
        }
    }


    selectFeeAccordingToPayment(feeObjectType) {
        const { payment, deliveryData, selectedRestaurant, orderTypes } = this.props;
        const { subtotal } = this.props.shoppingCart;
        let serviceFee = 0, dataObject = {};

        // if (orderTypes.selectedOrderType.id === 1) {
        //     dataObject = Object.keys(deliveryData).length === 0 ? selectedRestaurant : deliveryData.length > 0 ? deliveryData[0] : deliveryData;
        // } else if (orderTypes.selectedOrderType.id === 2 || orderTypes.selectedOrderType.id === 4 || orderTypes.selectedOrderType.id === 5) {
            dataObject = selectedRestaurant;
        //}

        if (dataObject.serviceFees) {
            dataObject.serviceFees.map(fee => {
                //we have apple pay with id=3 but we treat him like card payment
                if (this.props.isAppleGooglePaySelected) {
                    if (fee.tenderType.id === 1 && feeObjectType === 'feeValue') {
                        if(fee.percent) {
                            serviceFee = Number(subtotal) * (fee.amount / 100);

                            if(serviceFee < fee.minAmount) {
                                serviceFee = fee.minAmount;
                            } else if(serviceFee > fee.maxAmount) {
                                serviceFee = fee.maxAmount;
                            }
                        } else {
                            serviceFee = fee.amount;
                        }
                        serviceFee = checkIfServiceFeeIsWrongAndAmend(serviceFee);
                    } else if (fee.tenderType.id === 1 && feeObjectType === 'feeObject') {
                        serviceFee = fee;
                    }
                } else {
                    if (fee.tenderType.id === payment.selectedPaymentType.id && feeObjectType === 'feeValue') {
                        if(fee.percent) {
                            serviceFee = Number(subtotal) * (fee.amount / 100);

                            if(serviceFee < fee.minAmount) {
                                serviceFee = fee.minAmount;
                            } else if(serviceFee > fee.maxAmount) {
                                serviceFee = fee.maxAmount;
                            }
                        } else {
                            serviceFee = fee.amount;
                        }

                        serviceFee = checkIfServiceFeeIsWrongAndAmend(serviceFee);
                    } else if (fee.tenderType.id === payment.selectedPaymentType.id && feeObjectType === 'feeObject') {
                        serviceFee = fee;
                    }
                }
            });
        }

        return serviceFee;
    }

    changeState({ target }) {

        const { promotionPartner } = this.props.promotionPartners;

        this.setState({
            [target.name]: target.value,
        }, () => {

            if (promotionPartner && promotionPartner.id === 2) {
                return;
            }

            if (!this.state.promoCode) {
                this.props.dispatchPromoCodeToStore(this.state.promoCode, null);
            } else {
                this.props.dispatchPromoCodeToStore(this.state.promoCode, undefined);
                this.props.changeCodeManuallySet(true)
            }
        });
    }

    toggle() {
        this.setState({
            isSpecialInstructionsOpen: !this.state.isSpecialInstructionsOpen
        });
    }

    toggleModal() {
        const path = '/menu-screen';
        this.props.closeStartOrderScreen(path);
    }

    totalItemsCount = (orderProducts) => {
        let totalItemsCount = 0;

        orderProducts.map(product => {
            totalItemsCount += product.quantity;
        });

        return totalItemsCount;
    }

    renderProductsColumn() {
        const { productsCol, productsColCheckout } = styles;
        const { brandId } = this.props;
        let noProductsCondition = this.props.shoppingCart.orderProducts.length === 0;
        let productsColCopy = { ...productsCol }
        const isMobileView = window.innerWidth <= 865;
        const isPromotionsScreen = window.location.href.includes(`/brand/${brandId}/order/promotions`)
        const isMenuScreen = !isPromotionsScreen && !this.props.isPromotionsModalOpen && !this.props.isCheckoutScreen ? true : false;
        
        if(isMenuScreen) {
            productsColCopy = { ...productsColCopy, maxHeight: '100%',display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }
        }

        return (
            <div style={(window.location.href.includes('checkout') && window.screen.width < 415 ? productsColCheckout : productsColCopy)} className="product-list-container">
                {noProductsCondition ? this.renderNoProductMsg() : this.renderProductsListGroup()}
            </div>
        );
    }

    renderNoProductMsg() {
        const { emptyCartMsg } = styles;
        return (
            <p style={emptyCartMsg}>{i18n.t('screens:shoppingCart.emptyCart')}</p>
        );
    }

    renderProductsListGroup() {
        const { shoppingCart, estimateOrderTime, promotionPartners, giftCards } = this.props;
        const { orderProducts, subtotal, deliveryFee, discount, vat } = this.props.shoppingCart;
        const { productsFirstCol, flexStartCol, flexEndCol, startColParagraph, endColParagraph, noWrap } = styles;
        const isArabic = this.props.language === ARABIC;

        const currency = this.state.currency;
        const subtotalPrice = transformProductPrice(subtotal);
        const subtotalPriceWithCurrency = getPriceWithCurrencySymbol(subtotalPrice, currency, isArabic);
        const discountPrice = transformProductPrice(promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2 ? promotionPartners.promoPartnerDiscountTotal : discount);
        const discountPriceWithCurrency = getPriceWithCurrencySymbol(discountPrice, currency, isArabic);
        const deliveryFeePrice = transformProductPrice(deliveryFee);
        const deliveryFeePriceWithCurrency = getPriceWithCurrencySymbol(deliveryFeePrice, currency, isArabic);
        const vatPrice = transformProductPrice(vat.total);
        const vatPriceWithCurrency = getPriceWithCurrencySymbol(vatPrice, currency, isArabic);
        const discountName = shoppingCart.appliedPromotions.length > 0 ? shoppingCart.appliedPromotions[0].name : null; //this.discountName(); // when we can have more than one promotion

        const isDeliveryFeeHidden = this.props.orderTypes.selectedOrderType.value === 'Delivery' ? (this.props.selectedRestaurant.deliveryCharge !== null ? false : true) : true;

        let isDiscountHidden = this.props.shoppingCart.appliedPromotions.length === 0;
        if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2) {
            isDiscountHidden = !promotionPartners.promoPartnerDiscountTotal;
        }

        const hideWhenNoProductsInCart = orderProducts.length === 0;

        const showSimpleBillingMsg = (this.state.serviceFee !== undefined && this.state.serviceFee !== null && this.state.serviceFee !== 0);
        const simpleBillingCharge = this.state.serviceFee;
        const additionalSimpleBillingCharge = transformProductPrice(simpleBillingCharge);
        const additionalSimpleBillingChargeWithCurrency = getPriceWithCurrencySymbol(additionalSimpleBillingCharge, currency, isArabic);
        const hasNutrientsInCart = checkCartProductsNutrients(this.props.shoppingCart.orderProducts);
        const haveDiscount = this.props.shoppingCart.discount > 0;
        const nonDiscountableText = haveDiscount ? `(${i18n.t('screens:shoppingCart.nondiscountable')})` : '';
        const deliveryFeeNonDiscountMsg = haveDiscount && !this.props.shoppingCart.freeDelivery ? `(${i18n.t('screens:shoppingCart.nondiscountable')})` : '';
        const giftCardsTotalDiscount = giftCards.giftCards ? giftCards.giftCards.totalDiscount : null;
        //TODO fix this - maybe add new flag
        let stopCartInteractions = false;
        if (window.location.hash.includes('/checkout') || window.location.hash.includes('/promotions') || this.props.isPromotionsModalOpen) {
            stopCartInteractions = true;
        }

        return (
            <React.Fragment>
                <ListGroup className="product-list">
                    {
                        hasNutrientsInCart ?
                            <NutritionsList {...this.props} calledFrom={'shoppingCart'} />
                            : null

                    }
                    {
                        orderProducts.map((orderProduct, orderProductIndex) => {
                            let mealDealProducts = [];

                            if (orderProduct.mealDeal) {
                                mealDealProducts = orderProduct.questions;
                            }

                            return (
                                <ShoppingCartItem
                                    key={`${orderProductIndex}`}
                                    currency={currency}
                                    index={orderProductIndex}
                                    product={orderProduct}
                                    restaurant={this.props.selectedRestaurant}
                                    addOrSubstractItemQty={this.addOrSubstractItemQty}
                                    remove={this.removeItem}
                                    editProductFromCart={this.props.editProductFromCart}
                                    renderProductInfo={(orderProduct.mealDeal) ? this.renderMealDealProductsInfo(orderProduct, mealDealProducts, orderProductIndex) : this.renderRegularProductsInfo(orderProduct, orderProductIndex)}
                                    customerThemes={this.props.customerThemes}
                                    styles={styles}
                                    stopCartInteractions={stopCartInteractions}
                                    isArabic={isArabic}
                                />
                            );
                        })
                    }
                </ListGroup>
                <div style={productsFirstCol}>
                    <Col sm={8} xs={8} style={flexStartCol}>
                        <p style={startColParagraph} hidden={hideWhenNoProductsInCart}>
                            <span style={isArabic ? {float: 'right', paddingLeft: '2px'} : null}>{i18n.t('screens:shoppingCart.subtotal')}</span>{`(${totalItemsCount(this.props.shoppingCart)} ${i18n.t('screens:shoppingCart.subtotalItems')})`}
                        </p>
                        <p style={{ ...startColParagraph, ...noWrap }} hidden={!showSimpleBillingMsg}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {`${i18n.t('screens:shoppingCart.serviceFee')} ${nonDiscountableText}`}
                            </span>
                        </p>
                        <Discounts {...this.props} />

                        <p style={{ ...startColParagraph, ...noWrap }} hidden={hideWhenNoProductsInCart || isDeliveryFeeHidden}>
                            {`${i18n.t('screens:shoppingCart.deliveryFee')} ${deliveryFeeNonDiscountMsg}`}
                        </p>
                        {
                            vat.total > 0 ?
                                <p style={startColParagraph} hidden={hideWhenNoProductsInCart || estimateOrderTime.taxIncludeInPrice}>
                                    {i18n.t('screens:shoppingCart.vat')}
                                </p> : vat.taxes ? this.renderAllTaxes(true, vat.taxes, hideWhenNoProductsInCart, estimateOrderTime.taxIncludeInPrice) : null
                        }
                        
                        {
                            shoppingCart.deposits &&
                            <p style={{ ...startColParagraph, ...noWrap }}>{`${i18n.t('screens:shoppingCart.deposit')} ${nonDiscountableText}`}</p>
                        }
                        { giftCardsTotalDiscount ?
                            <p style={{ ...startColParagraph, ...noWrap }}>
                                {i18n.t('screens:checkoutScreen.giftCard')}
                            </p> : null
                        }
                    </Col>
                    <Col sm={4} xs={4} style={flexEndCol}>
                        <p style={endColParagraph} hidden={hideWhenNoProductsInCart}>{subtotalPriceWithCurrency}</p>
                        <p style={endColParagraph} hidden={!showSimpleBillingMsg} >{additionalSimpleBillingChargeWithCurrency}</p>
                        <p style={endColParagraph} hidden={hideWhenNoProductsInCart || isDiscountHidden}>{`- ${discountPriceWithCurrency}`}</p>
                        <p style={endColParagraph} hidden={hideWhenNoProductsInCart || isDeliveryFeeHidden}>{deliveryFeePriceWithCurrency}</p>

                        {
                            vat.total > 0 ?
                                <p style={endColParagraph} hidden={hideWhenNoProductsInCart || estimateOrderTime.taxIncludeInPrice}>{vatPriceWithCurrency}</p>
                                : vat.taxes ? this.renderAllTaxes(false, vat.taxes, hideWhenNoProductsInCart, estimateOrderTime.taxIncludeInPrice) : null
                        }
                        {
                            shoppingCart.deposits &&
                            <p style={endColParagraph}>{getPriceWithCurrencySymbol(transformProductPrice(shoppingCart.deposits), currency)}</p>
                        }
                        {
                            giftCardsTotalDiscount ?
                            <p style={endColParagraph}>{`${getPriceWithCurrencySymbol(transformProductPrice(giftCardsTotalDiscount), currency)}`}</p> : null
                        }
                    </Col>
                </div>
            </React.Fragment>
        );
    }

    renderAllTaxes(isText, taxes, hideWhenNoProductsInCart, taxIncludeInPrice) {
        if (!taxes) return null;
        const isArabic = this.props.language === ARABIC;
        const { currency } = this.state;
        const { startColParagraph, endColParagraph } = styles;
        const renderTaxesLabels = taxes.map(tax => {
            return <p key={`label${tax.id}`} style={startColParagraph} hidden={hideWhenNoProductsInCart || taxIncludeInPrice}>{tax.taxName ? `${tax.taxName} ${tax.taxPercent}%` : `${i18n.t('screens:shoppingCart.vat')} ${tax.taxPercent}%`}</p>
        });

        const renderTaxesPrices = taxes.map(tax => {
            return <p key={`tax${tax.id}`} style={endColParagraph} hidden={hideWhenNoProductsInCart || taxIncludeInPrice}>{getPriceWithCurrencySymbol(Number(tax.total).toFixed(2), currency, isArabic)}</p>
        });
        return (
            <Fragment>
                {isText ? renderTaxesLabels : renderTaxesPrices}
            </Fragment>
        );
    }

    renderProductExtraInfo(mealDealProduct) {
        const isArabic = this.props.language === ARABIC;

        if (mealDealProduct.selectedProduct.subQuestions === null) {
            mealDealProduct.selectedProduct.subQuestions = [];
        }

        let fullProductDescription = {
            noItems: null,
            extraItems: null
        }

        fullProductDescription = displayProductInformation(fullProductDescription, mealDealProduct.selectedProduct, isArabic);

        return fullProductDescription;
    }

    renderRegularProductsInfo(product, index) {
        const { innerColPadd, itemName, itemDescription, itemNotes, remove, removeDisabled, edit } = styles;
        const isHidden = product.instruction === null ? true : (product.instruction.notes === null ? true : (product.instruction.notes.length <= 0 ? true : false));
        const removeBtn = product.promotionFreeProduct ? removeDisabled : remove;
        const notes = product.instruction === null ? product.instruction : product.instruction.notes;
        let stopCartInteractions = false;
        if (window.location.hash.includes('/checkout') || window.location.hash.includes('/promotions') || this.props.isPromotionsModalOpen) {
            stopCartInteractions = true;
        }
        const isArabic = this.props.language === ARABIC;

        let fullProductDescription = {
            itemSize: null,
            noItems: null,
            extraItems: null
        }

        fullProductDescription = displayProductInformation(fullProductDescription, product);

        return (
            <React.Fragment>
                <Col style={innerColPadd} onClick={stopCartInteractions ? null : () => this.props.editProductFromCart(product, index)}>
                    <h6 className="long-text" style={itemName}>{product.name}</h6>
                    <p className="long-text" style={itemDescription} hidden={!fullProductDescription.itemSize}>{`${i18n.t('screens:shoppingCart.itemSize')} ${fullProductDescription.itemSize}`}</p>
                    <p className="long-text" style={itemDescription} hidden={!fullProductDescription.noItems.length}>{`${i18n.t('screens:shoppingCart.noItems')} ${fullProductDescription.noItems.join()}`}</p>
                    <p className="long-text" style={itemDescription} hidden={!fullProductDescription.extraItems.length}>{`${fullProductDescription.extraItems.join()}`}</p>
                    {/* ${i18n.t('screens:shoppingCart.extraItems')}  */}
                    <p className="long-text" style={itemNotes} hidden={isHidden}>{`"${notes}"`}</p>
                </Col>
                <p style={{ marginBottom: 6 }}>
                    <span
                        hidden={stopCartInteractions}
                        style={isArabic ? {...removeBtn, float: 'right', paddingLeft: '10px', paddingTop: '3px'} : removeBtn}
                        onClick={() => this.removeItem(index)}>{i18n.t('common:buttons.removeBtn')}</span>
                    <span
                        hidden={stopCartInteractions}
                        style={edit}
                        onClick={() => this.props.editProductFromCart(product, index)}>{i18n.t('common:buttons.editBtn')}</span>
                </p>
            </React.Fragment>
        );
    }

    renderMealDealProductsInfo(product, mealDealProducts, index) {
        const { innerColPadd, itemName, itemDescription, itemNotes, remove, edit, mealDealProductName } = styles;
        const isHidden = product.instruction === null ? true : (product.instruction.notes === null ? true : (product.instruction.notes.length <= 0 ? true : false));
        const notes = product.instruction === null ? product.instruction : product.instruction.notes;
        let stopCartInteractions = false;

        if (window.location.hash.includes('/checkout') || window.location.hash.includes('/promotions') || this.props.isPromotionsModalOpen) {
            stopCartInteractions = true;
        }

        return (
            <React.Fragment>
                <Col style={innerColPadd} onClick={stopCartInteractions ? null : () => this.props.editProductFromCart(product, index)}>
                    <h6 className="long-text" style={itemName}>{product.name}</h6>
                    <p className="long-text" style={itemNotes} hidden={isHidden}>{`"${notes}"`}</p>
                </Col>
                <p style={{ marginBottom: 0 }}>
                    <span
                        hidden={stopCartInteractions}
                        style={remove}
                        onClick={() => this.removeItem(index)}>{i18n.t('common:buttons.removeBtn')}</span>
                    <span
                        hidden={stopCartInteractions}
                        style={edit}
                        onClick={() => this.props.editProductFromCart(product, index)}>{i18n.t('common:buttons.editBtn')}</span>
                </p>
                {
                    mealDealProducts.map((mealDealProduct, mealDealProductIndex) => {
                        let fullProductDescription = {
                            itemSize: null,
                            noItems: null,
                            extraItems: null
                        }

                        fullProductDescription = this.renderProductExtraInfo(mealDealProduct);

                        return (
                            <div key={`${mealDealProductIndex}`} onClick={stopCartInteractions ? null : () => this.props.editProductFromCart(product, index)}>
                                <p className="long-text" style={mealDealProductName}>{mealDealProduct.selectedProduct.name}</p>
                                <p className="long-text" style={itemDescription} hidden={!fullProductDescription.noItems.length}>
                                    {`${i18n.t('screens:shoppingCart.noItems')} ${fullProductDescription.noItems.join()}`}
                                </p>
                                <p dir="ltr" className="long-text" style={itemDescription} hidden={!fullProductDescription.extraItems.length}>
                                    {fullProductDescription.extraItems.join()}
                                </p>
                            </div>
                        );
                    })
                }
            </React.Fragment>
        );
    }

    addOrSubstractItemQty(addOrSubstract, currentProduct, index) {
        const { orderProducts } = this.props.shoppingCart;
        const { restaurantId } = this.props.selectedRestaurant;

        let newQty, updatedProducts;
        updatedProducts = orderProducts.slice(0);

        const filteredproduct = {
            id: currentProduct.id,
            name: currentProduct.name,
            price: currentProduct.price,
            category: getProductCategory(currentProduct.id, this.props.products, this.props.categories),
            quantity: 1, // Hardcoded because it always adds/substracts by 1
            currency: currentProduct.currency
        }

        if (addOrSubstract) {
            newQty = currentProduct.quantity += 1;
            pushDataToGoogleTagManager('event', 'addToCart', this.props.pwaAppRunning, filteredproduct);
        } else {
            newQty = currentProduct.quantity -= 1;
            if (newQty > 0) {
                pushDataToGoogleTagManager('event', 'removeFromCart', this.props.pwaAppRunning, filteredproduct);
            }
        }

        orderProducts.map(i => {
            if (index === i) {
                updatedProducts.splice(i, 1, currentProduct);
            }
        });

        if (newQty <= 0) {
            if (updatedProducts.length === 0) {
                this.setState({
                    promoCode: ''
                });
            }
            this.removeItem(index);
        } else {
            this.props.addOrSubstractItem(updatedProducts, restaurantId);
        }
    }

    removeItem(index) {
        const { orderProducts } = this.props.shoppingCart;
        const { restaurantId } = this.props.selectedRestaurant;
        let updatedProducts = orderProducts.slice(0);
        updatedProducts.splice(index, 1);

        if (updatedProducts.length === 0) this.setState({ promoCode: '' });
        this.props.removeItemFromCart(updatedProducts, restaurantId);

        const removedProduct = orderProducts[index];
        const filteredproduct = {
            id: removedProduct.id,
            name: removedProduct.name,
            price: removedProduct.price,
            category: getProductCategory(removedProduct.id, this.props.products, this.props.categories),
            quantity: removedProduct.quantity
        }
        pushDataToGoogleTagManager('event', 'removeFromCart', this.props.pwaAppRunning, filteredproduct);
    }

    renderPlaceoOrderColumn() {
        const { placeOrderColumn, firstCol, placeoOrderSecondCol, flexStartCol, flexEndCol, startColParagraph, totalStyle, endColParagraph,
            totalValue, minOrderAmount, noWrap } = styles;
        const isArabic = this.props.language === ARABIC;

        const { shoppingCart, selectedRestaurant, menuPreviewMode, promotionPartners, brandId } = this.props;
        const { tip, total, orderProducts } = this.props.shoppingCart;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const previewMode = menuPreviewMode;
        const currency = this.state.currency;

        const isTipHidden = tip > 0 || (this.props.isCheckoutScreen && this.props.selectedRestaurant.deliveryTipEnabled) ? false : true;

        const hideWhenNoProductsInCart = shoppingCart.orderProducts.length === 0;
        let isComo = promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2;
        let isPromoCodeFieldHidden = previewMode ? true : hidePromoCodesField(this.props.availableDiscounts, this.props.loyalty.loyalty);
        const isPromotionsScreen = window.location.href.includes(`/brand/${brandId}/order/promotions`)
        const isLoyaltyCode = this.props.loyalty ? this.props.loyalty.oldestActiveCode : false;
        if ((isComo && this.props.isPromotionsModalOpen) || (isComo && isPromotionsScreen)) {
            isPromoCodeFieldHidden = false;
        }

        let tipValue = this.props.selectedRestaurant.deliveryTipEnabled ? tip : 0;
        const tipPrice = transformProductPrice(tipValue);
        const tipPriceWithCurrency = getPriceWithCurrencySymbol(tipPrice, currency, isArabic);

        const isPwa = this.props.pwaAppRunning;
        const isDinePay = this.props.orderTypes.selectedOrderType.value === 'In Store';
        let totalOrderPrice = total;
        if (!orderProducts.length) {
            totalOrderPrice = 0;
        }
        
        const totalPrice = transformProductPrice(totalOrderPrice);
        const totalPriceWithCurrency = getPriceWithCurrencySymbol(totalPrice, currency, isArabic);
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        // When we have applied gift cards we use the Total from Gift Cards
        const totalPriceWithGiftCard = transformProductPrice(shoppingCartTotalWithGiftCard);
        const totalPriceWithGiftCardWithCurrency = getPriceWithCurrencySymbol(totalPriceWithGiftCard, currency, isArabic);
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;

        const serviceFeeIconColor = this.props.customerThemes.selectedTheme.primaryFontColor;
        const showSimpleBillingMsg = (this.state.serviceFee !== undefined && this.state.serviceFee !== null && this.state.serviceFee !== 0);

        return (
            <React.Fragment>
                {isPromoCodeFieldHidden && !isLoyaltyCode ? null : this.renderPromoCodes()}
                <Col sm={12} style={placeOrderColumn}>
                    <Col style={firstCol}>
                        <Col sm={9} style={flexStartCol}>
                            <p style={startColParagraph} hidden={hideWhenNoProductsInCart || isTipHidden}>
                                {i18n.t('screens:shoppingCart.tip')}
                                <span
                                    hidden={!this.props.isCheckoutScreen}
                                    style={{ fontSize: 13, color: primaryColor, cursor: 'pointer', padding: '2px 0px 0px 5px' }}
                                    onClick={isPwa ? () => this.props.history.push(`/brand/${brandId}/order/tip`) : () => this.toggleTipScreen()}>
                                    {i18n.t('screens:shoppingCart.edit')}
                                </span>
                            </p>
                            <p style={{ ...startColParagraph, ...noWrap, ...totalStyle }}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <p style={totalStyle}>{i18n.t('screens:shoppingCart.total')}</p>
                                        <div onClick={this.toggleServiceFeeModal} hidden={!showSimpleBillingMsg}>
                                            <span style={{ paddingLeft: '5px', cursor: 'pointer', border: '1px solid gray', borderRadius: '100%', display: 'flex', padding: '0px', margin: '5px', background: serviceFeeIconColor }}>
                                                <MaterialIcon icon='info' size={15} color="#fff" />
                                            </span>
                                        </div>
                                </span>
                            </p>
                        </Col>
                        <Col sm={3} style={flexEndCol}>
                            <p style={Object.assign({}, endColParagraph, { display: 'flex', flexDirection: 'row' })} hidden={hideWhenNoProductsInCart || isTipHidden}>
                                {/* <span style={{ fontVariant: 'normal', display: 'flex', border: '1px solid #dcdcdc', borderRadius: '100%', padding: 2, margin: 3, cursor: 'pointer', position: 'relative', bottom: 2 }} onClick={() => this.addOrSubstractTip(false)}>
                                    { <MaterialIcon icon="remove" color="#656565" size={14} /> }
                                    { <span style={minusOperatorTip}>-</span> }
                                    <MaterialIcon icon="remove" color={primaryFontColor} size={15} />
                                </span>
                                <span style={{ fontVariant: 'normal', display: 'flex', border: '1px solid #dcdcdc', borderRadius: '100%', padding: 2, margin: 3, cursor: 'pointer', position: 'relative', bottom: 2 }} onClick={() => this.addOrSubstractTip(true)}>
                                    { <MaterialIcon icon="add" color="#656565" size={14} /> }
                                    { <span style={plusOperatorTip}>+</span> }
                                    <MaterialIcon icon="add" color={primaryFontColor} size={15} />
                                </span> */}
                                {tipPriceWithCurrency}
                            </p>
                            <p style={totalValue}>{(shoppingCartTotalWithGiftCard >= 0 && isAppliedGiftCards) ? totalPriceWithGiftCardWithCurrency : totalPriceWithCurrency}</p>
                        </Col>
                    </Col>
                    <Col style={placeoOrderSecondCol}>
                        <MinMaxOrderAmount
                            style={minOrderAmount}
                            isCheckout={true}
                            selectedRestaurant={selectedRestaurant}
                            shoppingCart={shoppingCart}
                            isArabic={isArabic}
                        />
                        <p hidden={(previewMode == true || previewMode == 'true') ? false : true} style={minOrderAmount}>{`*${i18n.t('screens:shoppingCart.previewMode')}`}</p>
                        <p hidden={!this.props.estimateOrderTime.cookTime || isMobileOnly || isDinePay} style={minOrderAmount}>
                            {`+${this.props.estimateOrderTime.cookTime} ${i18n.t('screens:shoppingCart.minsPrepRquired')}`}
                        </p>
                    </Col>
                </Col>
            </React.Fragment>
        );
    }

    renderPromoCodes() {

        const { userSavedData } = this.props.como;
        const { promotionPartner } = this.props.promotionPartners;
        const { errorMessage } = this.props.shoppingCart;
        const { restaurantId } = this.props.selectedRestaurant;
        const {
            primaryColor,
            buttonsFont,
            fontFamilyType,
            fontBold,
            fontItalic,
            uppercase,
            chefNoteBackground,
            inactiveColor
        } = this.props.customerThemes.selectedTheme;
        const isComo = (promotionPartner && promotionPartner.id === 2);
        const disableCreditBalanceOption = promotionPartner && promotionPartner.comoConfigs ? promotionPartner.comoConfigs.disableCreditBalanceOption : null;

        let extraStyles = {};
        const isArabic = this.props.language === ARABIC;
        if (this.state.promoCode && (window.outerWidth < 992 || isComo)) {
            extraStyles.backgroundColor = primaryColor;
            extraStyles.cursor = 'pointer';
        }

        const {
            promoCodesIconStyle,
            promoCodesAddonPrepend,
            promoCodesAddonPrependRTL,
            promoCodesInput,
            promoCodesAddon,
            promoCodesAddonRTL,
            promoCodesColumn,
            errorMsg
        } = styles;

        const applyPromoCodeBtnStyle = {
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            color: buttonsFont,
            width: '100%',
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        };

        let hiddenPromoCodes = this.props.shoppingCart.orderProducts.length === 0;
        const isPromoCodeApplyHidden = window.outerWidth <= 992 || isComo ? !this.state.promoCode ? true : false : true;
        let isApplyDisabled = false;
        let onApplyClick = () => this.props.calculatePromotion(restaurantId);
        if (isComo) {
            const isPromotionsScreen = window.location.href.includes('promotions');
            const promos = userSavedData.getBenefitsResponse && userSavedData.getBenefitsResponse.redeemAssets ? userSavedData.getBenefitsResponse.redeemAssets : [];
            isApplyDisabled = isCodeAppliedSuccessfully(this.state.promoCode, promos);

            onApplyClick = () => this.props.dispatchPromoCodeToStore(this.state.promoCode, null, undefined, true);

            if (isApplyDisabled) {
                onApplyClick = () => null;
                extraStyles.backgroundColor = inactiveColor;
                extraStyles.cursor = 'auto';
            }
            if (!isPromotionsScreen && !this.props.isPromotionsModalOpen) {
                hiddenPromoCodes = true;
            }
        }

        return (
            <Col sm={12} style={promoCodesColumn} hidden={hiddenPromoCodes}>
                <InputGroup style={{ backgroundColor: chefNoteBackground }}>
                    <InputGroupAddon addonType="prepend" style={isArabic ? promoCodesAddonPrependRTL : promoCodesAddonPrepend}>
                        <img src={promoCodesIcon} style={promoCodesIconStyle} />
                    </InputGroupAddon>
                    <Input
                        id="cart-promo-codes-input"
                        name="promoCode"
                        type="text"
                        style={promoCodesInput}
                        placeholder={i18n.t('screens:shoppingCart.promoCodes')}
                        onChange={this.changeState}
                        value={this.state.promoCode}
                        className='promoInput'
                        disabled={isApplyDisabled} />
                    <InputGroupAddon
                        addonType="append"
                        style={Object.assign({}, isArabic ? promoCodesAddonRTL : promoCodesAddon, extraStyles)}
                        onClick={() => onApplyClick()}
                        disabled={isApplyDisabled}>
                        {/* <TickSVG width={24} height={24} fill={'#fff'} /> */}
                        <span hidden={isPromoCodeApplyHidden} style={applyPromoCodeBtnStyle}>{i18n.t('screens:shoppingCart.apply')}</span>
                        {/* <img hidden={true} src={checkIconSrc} style={promoCodesAddOnIcon} /> */}
                    </InputGroupAddon>
                </InputGroup>
                <p hidden={errorMessage === null} style={errorMsg}>{errorMessage}</p>
                { this.props.comoInformation && !disableCreditBalanceOption ? <Row style={{ marginTop: 10 }}><ComoPayment {...this.props.comoInformation}/></Row> : null }
            </Col>
        );
    }

    preventEnter(e) {

        const { promotionPartner } = this.props.promotionPartners;
        const { orderProducts, promoCode, subtotal } = this.props.shoppingCart;
        let inputKeyCode = e.keyCode ? e.keyCode : e.which;

        const restaurantId = this.props.selectedRestaurant.restaurantId;

        const orderPromotionDto = {
            orderedProducts: transformShoppingCartProducts(orderProducts, this.props.selectedRestaurant.allowToppingSubstitution),
            total: subtotal,
            orderType: this.props.orderTypes.selectedOrderType,
            promoCode: promoCode
        }

        if (this.state.promoCode !== null && promotionPartner.id !== 2) {
            this.props.dispatchPromoCodeToStore(promoCode);
        }

        if (inputKeyCode === 13) {
            if (this.state.promoCode !== null && this.state.promoCode !== '') {
                this.props.calculatePromotion(restaurantId, orderPromotionDto);
            }
            e.preventDefault();
        }
    }

    addOrSubstractTip(addTip) { // addTipp = false when decreasing tip // must be deleted in future
        const { bonusTip } = this.props.shoppingCart;
        let currentTip = bonusTip;

        if (addTip) {
            currentTip += 1;
            this.props.increaseOrDecreaseTip(currentTip);
        } else if (currentTip > 0) {
            currentTip -= 1;
            this.props.increaseOrDecreaseTip(currentTip);
        }
    }

    moveToCheckout() {
        // Sends promo code to store and calls applyPromotion service
        if (this.state.promoCode) {
            this.props.dispatchPromoCodeToStore(this.state.promoCode, null);
        }
    }

    renderModalWindow() {
      
        if (this.props.startOrder.currentPagePath == '/menu-screen/sms-confirmation') {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <SmsConfirmationScreen toggle={this.toggleModal} isCheckoutLogin={true} hideBackButton={true} />
                </Modal>
            );
        } else if (this.props.startOrder.currentPagePath == '/menu-screen/user-credentials') {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <UserCredentialsScreen toggle={this.toggleModal} isCheckoutLogin={true} history={this.props.history} />
                </Modal>
            );
        } else if (this.props.startOrder.currentPagePath == '/menu-screen/forgotten-email') {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <ForgottenEmailScreen toggle={this.toggleModal} isOrderDone={true} hideBackButton={true} />
                </Modal>
            );
        } else if (this.props.startOrder.currentPagePath == '/menu-screen/phone-number') {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <PhoneNumberScreen toggle={this.toggleModal} isOrderDone={true} isCheckoutLogin={true} />
                </Modal>
            );
        } else if (this.props.startOrder.currentPagePath == '/menu-screen/social-response') {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <SocialSignInResponse toggle={this.toggleModal} isOrderDone={true} isCheckoutLogin={true} />
                </Modal>
            );
        } else if (this.props.startOrder.currentPagePath == '/menu-screen/social-credentials') {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <SocialUserCredentialsScreen toggle={this.toggleModal} isOrderDone={true} hideBackButton={true} />
                </Modal>
            );
        } else {
            return (
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened} toggle={this.toggle} size='lg'>
                    <LoginTypesScreen toggle={this.toggleModal} isOrderDone={true} hideBackButton={true} isCheckoutLogin={true} />
                </Modal>
            );
        }
    }

    redirectToMenuuHomepage = () => {
        let integrationDetected = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            integrationDetected = true;
        }

        if (integrationDetected) {
            sendMessageToParentSite('redirect');
        } else {
            window.location.href = "https://menuu.com/?utm_source=menuu&utm_medium=link&utm_campaign=powered-by-menuu";
        }
    };

    applyTheme() {
        const isPwa = this.props.pwaAppRunning;
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, textDecoration, mainTextColor, inactiveColor, menuBackground, primaryFontColor, menuItemsBackground, buttonsFont, inputsFontColor, fontFamilyType, fontBold, fontItalic, uppercase, chefNoteBackground } = this.props.customerThemes.selectedTheme;

        customizedStyles.stickyBottomCartActive.backgroundColor = primaryColor;
        customizedStyles.checkOutBtn.backgroundColor = primaryColor;
        customizedStyles.checkOutBtnDisabled.backgroundColor = inactiveColor;
        customizedStyles.totalValue.color = primaryColor;
        customizedStyles.remove.color = primaryColor;
        customizedStyles.remove.fontWeight = fontWeight;
        customizedStyles.remove.textDecoration = textDecoration;
        customizedStyles.edit.color = primaryColor;
        customizedStyles.edit.fontWeight = fontWeight;
        customizedStyles.edit.textDecoration = textDecoration;
        customizedStyles.itemPriceGreen.color = primaryColor;
        customizedStyles.itemName.fontWeight = fontBold === 'bold' ? fontBold : fontWeight;
        customizedStyles.startColParagraph.color = mainTextColor;
        customizedStyles.endColParagraph.color = mainTextColor;
        customizedStyles.minOrderAmount.color = mainTextColor;
        customizedStyles.itemDescription.color = mainTextColor;
        customizedStyles.emptyCartMsg.color = mainTextColor;
        customizedStyles.totalStyle.color = primaryFontColor;
        customizedStyles.itemName.color = primaryFontColor;
        customizedStyles.itemPrice.color = primaryFontColor;
        customizedStyles.promoCodesInput.backgroundColor = chefNoteBackground;
        customizedStyles.promoCodesInput.color = inputsFontColor
        customizedStyles.serviceNote.color = mainTextColor;
        customizedStyles.checkOutBtnDisabled.color = buttonsFont;
        customizedStyles.checkOutBtn.color = buttonsFont;
        customizedStyles.stickyBottomCart.color = buttonsFont;
        customizedStyles.badgeStyle.backgroundColor = menuItemsBackground;
        customizedStyles.badgeStyle.color = primaryFontColor;
        customizedStyles.itemNotes.color = mainTextColor;
        customizedStyles.itemName.fontFamily = fontFamilyType;
        customizedStyles.itemName.fontStyle = fontItalic;
        customizedStyles.itemName.textTransform = uppercase;
        customizedStyles.itemPrice.fontFamily = fontFamilyType;
        customizedStyles.itemPrice.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.itemPrice.fontStyle = fontItalic;
        customizedStyles.itemPrice.textTransform = uppercase;
        customizedStyles.endColParagraph.fontFamily = fontFamilyType;
        customizedStyles.endColParagraph.fontWeight = fontBold === 'bold' ? fontBold : 300;;
        customizedStyles.endColParagraph.fontStyle = fontItalic;
        customizedStyles.endColParagraph.textTransform = uppercase;
        customizedStyles.totalValue.fontFamily = fontFamilyType;
        customizedStyles.totalValue.fontWeight = fontBold === 'bold' ? fontBold : 300;;
        customizedStyles.totalValue.fontStyle = fontItalic;
        customizedStyles.totalValue.textTransform = uppercase;
        customizedStyles.mealDealProductName.color = primaryFontColor;
        customizedStyles.menuuLogo.backgroundColor = isPwa ? menuBackground : '';
        customizedStyles.menuuLogoLargeImage.backgroundColor = menuItemsBackground;
        customizedStyles.menuuLogoLargeImageCheckout.backgroundColor = menuItemsBackground;
        styles = customizedStyles;

        return styles;
    };

    toggleTipScreen = () => {
        this.setState({ isTipModalOpen: !this.state.isTipModalOpen }, () => {
            this.props.setMenuOrOrderHistoryScreen(this.state.isTipModalOpen);
        });
    }

    toggleFreeProductModal = () => {
        this.setState({
            isFreeProductModalOpen: !this.state.isFreeProductModalOpen
        })
    }

    toggleServiceFeeModal = () => {
        this.setState({
            isServiceFeeModalOpen: !this.state.isServiceFeeModalOpen
        });
    }

    checkHasFreeProduct = (prevProducts, products) => {
        let currentFreeProduct = {};
        let prevFreeProduct = {};

        products.map((product) => {
            if (product.promotionFreeProduct) {
                currentFreeProduct = product;
            }
        })

        prevProducts.map(prevProduct => {

            if (prevProduct.promotionFreeProduct) {
                prevFreeProduct = prevProduct
            }
        })

        if (prevFreeProduct.id !== currentFreeProduct.id && Object.entries(currentFreeProduct).length) {
            this.toggleFreeProductModal();
        }
    }

    render() {
        // console.log(this.props.giftCards.giftCards, 'GIFT CARDSS')
        // TODO this line below is old piece of code but setting currency in state DOES NOT work without it. SC MUST be have some state management refactoring SOON !!!
        this.copyStateFromProps();
        const { menuuLogoColor } = this.props.customerThemes.selectedTheme;
        const { brandId } = this.props;
        const isArabic = this.props.language === ARABIC;
        const enableLargeImageDesign = (this.props.selectedRestaurant ? this.props.selectedRestaurant.enableLargeImageDesign : false) || (this.props.estimateOrderTime ? this.props.estimateOrderTime.enableLargeImageDesign : false);
        let { form, landscapeForm, formSectionsWrapper, menuuLogo, serviceNote, promotionsDesktopForm, menuuLogoLargeImage, menuuLogoLargeImageCheckout, largeImageForm } = styles;

        const isMobileView = window.innerWidth <= 865;
        const isLandscapeView = (window.orientation == 90 || window.orientation == -90) ? true : false;
        const isMobileLandscapeView = (isLandscapeView) ? (window.outerHeight <= 575 && window.outerHeight > 0) ? true : false : true;
        const isCartScreen = window.location.href.includes(`/brand/${brandId}/order/cart`)
        const isPromotionsScreen = window.location.href.includes(`/brand/${brandId}/order/promotions`)
        const isCheckoutScreen = this.props.isCheckoutScreen;
        const isMenuScreen = !isPromotionsScreen && !this.props.isPromotionsModalOpen && !isCheckoutScreen && !isMobileView && !this.props.pwaAppRunning ? true : false;
        const isAppMobileMenuScreen = !isPromotionsScreen && !this.props.isPromotionsModalOpen && !isCheckoutScreen && (isMobileView || this.props.pwaAppRunning) ? true : false;
       
        const formStyle = this.props.isPromotionsModalOpen && !isMobileView ? promotionsDesktopForm : isMenuScreen && this.props.estimateOrderTime.showMenuuLogo ? {...largeImageForm, height: 'calc(100vh - 40px)'} : isMenuScreen && !this.props.estimateOrderTime.showMenuuLogo ? {...largeImageForm, height: '100vh'}  : (isMobileView || this.props.pwaAppRunning) && isAppMobileMenuScreen ? {...largeImageForm, height: 'calc(100% - 40px)' } : form;

        const isLandscapeCartStyle = (window.innerWidth == 1440 && 698 <= window.outerHeight <= 730 && window.outerHeight == 0) ? true : false;

        const shoppingCartTabletStyle = (isLandscapeCartStyle) ? { width: '100%' } : {};// tablet landscape
        const isMobileDeviceWithHighResolution = (window.screen.width <= 750 && window.screen.height >= 1330 && isLandscapeView) ? true : false;

        const isMenuOrOrderHistoryScreen = this.props.storeLocalStorage.isMenuOrOrderHistory;
        const isTipModalOpen = this.state.isTipModalOpen && this.props.selectedRestaurant.deliveryTipEnabled && isMenuOrOrderHistoryScreen;

        // Check if UPSELL products are available 
        let isUpsellAvailable = this.props.upsellProducts.length;

        let integrationDetected = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            integrationDetected = true;
        }

        menuuLogo = isUpsellAvailable && integrationDetected && isMobileOnly ? { ...menuuLogo, padding: '3vh 0vh 35vh' } : { ...menuuLogo };

        if (this.props.isPlaceOrderClicked) {
            this.placeOrder();
            this.props.clearPlaceOrderClick();
        }
        // if (this.props.isCartStickedToBottom && isMobileLandscapeView || isMobileDeviceWithHighResolution) {
        //     // WEB mobile/tablet in portrait
        //     return (
        //         <React.Fragment>
        //             <ShoppingCartButton
        //                 isAppleGooglePaySelected={this.props.isAppleGooglePaySelected}
        //                 isCardValid={this.props.isCardValid}
        //                 paymentId={this.props.paymentId}
        //                 history={this.props.history}
        //             />
        //             {this.renderModalWindow()}
        //             {
        //                 isTipModalOpen ?
        //                     <CommonModal isOpen={isTipModalOpen} dialogName='tips' toggle={this.toggleTipScreen} className='tips'>
        //                         <TipScreen
        //                             isOpen={isTipModalOpen}
        //                             onToggle={this.toggleTipScreen}
        //                             tip={transformProductPrice(this.props.shoppingCart.tip)}
        //                             currency={getCurrencySymbol(this.props.selectedRestaurant.currency)}
        //                             saveTip={this.props.increaseOrDecreaseTip}
        //                         />
        //                     </CommonModal> : null
        //             }
        //             {
        //                 this.state.isFreeProductModalOpen ?
        //                     <CommonModal isOpen={this.state.isFreeProductModalOpen} dialogName={`freeProduct`} toggle={this.toggleFreeProductModal} className='freeProduct'>
        //                         <FreeProduct
        //                             shoppingCart={this.props.shoppingCart}
        //                             onToggle={this.toggleFreeProductModal}
        //                             onEdit={this.props.editProductFromCart}
        //                             customerThemes={this.props.customerThemes}
        //                         />
        //                     </CommonModal> : null
        //             }
        //         </React.Fragment>
        //     );
        
        let url, shopCartCheckoutBtnStyle, isPwa, isCheckout, pwaAppStyles;
        shopCartCheckoutBtnStyle = {};
        isPwa = this.props.pwaAppRunning;
        url = window.location.href;
        isCheckout = url.includes('checkout');

        if (isPwa) {
            pwaAppStyles = {
                boxShadow: 'none',
                maxHeight: 'none'
            }
        } else if (!isPwa && isCheckout && window.outerWidth >= 768) {
            shopCartCheckoutBtnStyle = {
                padding: 0
            }
        } else if (isCheckout && window.screen.width < 415) {
            pwaAppStyles = {
                maxHeight: 'none'
            }
        }
        // WEB desktop/tablet in landscape/APP
        // Check if UPSELL products are available 
        // let isUpsellAvailable = this.props.upsellProducts.length;

        if (window.location.hash.includes('/checkout') || window.location.hash.includes('/promotions') || this.props.isPromotionsModalOpen) {
            isUpsellAvailable = false;
        }

        return (
            <React.Fragment>
                <div className="shoppingCartMobile" style={Object.assign({}, formStyle, shoppingCartTabletStyle, shopCartCheckoutBtnStyle, pwaAppStyles, { backgroundColor: this.props.customerThemes.selectedTheme.menuItemsBackground })} onKeyDown={this.preventEnter.bind(this)}>
                        <ShoppingCartButton
                            isCardValid={this.props.isCardValid}
                            isAppleGooglePaySelected={this.props.isAppleGooglePaySelected}
                            paymentId={this.props.paymentId}
                            history={this.props.history}
                            toggleCartModal={this.props.toggleCartModal}
                            isCartModalOpen={this.props.isCartModalOpen}
                        />
                    { isMenuScreen || isAppMobileMenuScreen ?
                        <div
                            id="customCloseBtn" className="shoppingCartCloseButton" onClick={() => this.props.toggleCartModal()}
                            style={isArabic ? {position: 'absolute', top: 23, left: 10, border: 'none'} : {position: 'absolute', top: 23, right: 10, border: 'none'}}>
                        </div> : null
                    }
                    {this.props.shoppingCart.orderProducts.length > 0 && this.props.serviceNote && this.props.serviceNote !== '' ?
                        <div style={{ marginBottom: 15 }}>
                            <div style={serviceNote}>
                                {this.props.serviceNote}
                            </div>
                        </div>
                        : null
                    }
                    {this.renderProductsColumn()}
                    <div style={formSectionsWrapper}>
                        {this.renderPlaceoOrderColumn()}
                        {
                            isUpsellAvailable ?
                                <UpsellProducts
                                    currency={this.state.currency}
                                    upsellProducts={this.props.upsellProducts}
                                    upsellMessage={this.props.upsellMessage}
                                    orderProducts={this.props.shoppingCart.orderProducts}
                                    selectProduct={this.props.selectProduct}
                                    theme={this.props.customerThemes.selectedTheme}
                                    isArabic={isArabic}
                                    enableLargeImageDesign={enableLargeImageDesign} /> : null
                        }
                    </div>
                    {this.renderModalWindow()}
                </div>
                { isPwa || isMobileView || this.props.estimateOrderTime.showMenuuLogo ?
                    <Col sm={12} md={12} style={isMobileView || isPwa ? {...menuuLogoLargeImage, paddingBottom: '65px'} : this.props.isCheckoutScreen ? menuuLogoLargeImageCheckout : menuuLogoLargeImage}>
                        <MenuuLogoSVG width={100} height={35} fill={menuuLogoColor} style={{ cursor: 'pointer' }} onClick={this.redirectToMenuuHomepage} />
                    </Col> : null
                }
                {
                    isTipModalOpen && !isPwa ?
                        <CommonModal isOpen={isTipModalOpen} dialogName={`tips ${isMobileOnly ? 'mobileTips' : ''}`} toggle={this.toggleTipScreen} className='tips'>
                            <TipScreen
                                isOpen={isTipModalOpen}
                                onToggle={this.toggleTipScreen}
                                tip={transformProductPrice(this.props.shoppingCart.tip)}
                                currency={getCurrencySymbol(this.props.selectedRestaurant.currency)}
                                saveTip={this.props.increaseOrDecreaseTip}
                                isMobile={isMobileOnly}
                            />
                        </CommonModal> : null
                }
                {
                    this.state.isFreeProductModalOpen ?
                        <CommonModal isOpen={this.state.isFreeProductModalOpen} dialogName={`freeProduct`} toggle={this.toggleFreeProductModal} className='freeProduct'>
                            <FreeProduct
                                shoppingCart={this.props.shoppingCart}
                                onToggle={this.toggleFreeProductModal}
                                onEdit={this.props.editProductFromCart}
                                customerThemes={this.props.customerThemes}
                            />
                        </CommonModal> : null
                }
                {
                    this.state.isServiceFeeModalOpen ?
                    <CommonModal isOpen={this.state.isServiceFeeModalOpen} dialogName={`serviceFeeDescription ${isMobileOnly ? 'mobileFee' : ''}`} toggle={this.toggleServiceFeeModal} className='serviceFeeDescription'>
                        <ServiceFeeScreen
                            customerThemes={this.props.customerThemes}
                            currencySymbol = {this.props.selectedRestaurant.currency}
                            percent={this.props.shoppingCart.feeObject.percent}
                            percentAmount = {this.props.shoppingCart.feeObject.amount}
                            minAmount={this.props.shoppingCart.feeObject.minAmount}
                            maxAmount={this.props.shoppingCart.feeObject.maxAmount}
                            serviceFee={this.props.shoppingCart.serviceFee}
                            isArabic={this.props.language}
                            onToggle={this.toggleServiceFeeModal}
                        />
                    </CommonModal> : null
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        estimateOrderTime: state.estimateOrderTime,
        customer: state.customer,
        isStartOrderScreenOpened: state.startOrder.startOrderScreenOpened,
        shoppingCart: state.shoppingCart,
        startOrder: state.startOrder,
        orderTypes: state.orderTypes,
        selectedProduct: state.selectedProduct,
        payment: state.payment,
        menuPreviewMode: state.menuPreviewMode.previewMode,
        customerThemes: state.customerThemes,
        availableDiscounts: state.availableDiscounts,
        pwaAppRunning: state.pwaAppRunning,
        deliveryData: state.deliveryData,
        selectedRestaurant: state.selectedRestaurant,
        loyalty: state.loyalty,
        upsellProducts: state.upsellProducts.upsellProducts,
        upsellMessage: state.upsellProducts.upsellMessage,
        paymentProvider: state.paymentProvider,
        products: state.restaurantProducts,
        categories: state.restaurantCategories,
        serviceNote: state.menuDescription.serviceNote,
        storeLocalStorage: state.storeLocalStorage,
        promotionPartners: state.promotionPartners,
        como: state.como,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen,
        isShoppingCartModalOpen: state.commonReducer.isShoppingCartModalOpen,
        brandId: state.brand.id,
        language: state.storeLocalStorage.language,
        giftCards: state.giftCards
    }
}

export default connect(mapStateToProps, {
    addOrSubstractItem,
    removeItemFromCart,
    increaseOrDecreaseTip,
    openStartOrderScreen,
    closeStartOrderScreen,
    placeOrder,
    getSelectedProduct,
    calculatePromotion,
    dispatchPromoCodeToStore,
    dispatchServiceFeeToStore,
    getUpsellProducts,
    availableTimeSlot,
    errorProcessingPayment,
    setMenuOrOrderHistoryScreen,
    saveLongestCookTime,
    loadAllAvailableDiscounts,
    togglePromotionsModal,
    toggleShoppingCartModal,
    clearGiftCardsState,
    applyGiftCard,
    clearGifCardsTotalDiscount,
    changeCodeManuallySet
}, null, { pure: false })(ShoppingCart);

let styles = {
    form: {
        backgroundColor: '#fff',
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 186px)'
    },
    largeImageForm: {
        backgroundColor: '#fff',
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 40px)'
    },
    landscapeForm: {
        backgroundColor: '#fff',
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        padding: 0,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 186px)'
    },
    promotionsDesktopForm: {
        backgroundColor: '#fff',
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 186px)',
        maxWidth: '480px',
        margin: '30px auto 0px'
    },
    stickyBottomCart: {
        bottom: 0,
        width: '100%',
        height: 65,
        zIndex: 1040,
        color: '#fff',
        fontSize: 22,
        border: 'none',
        textAlign: 'center',
        paddingTop: 5,
        borderRadius: 0,
        fontFamily: 'Roboto',
        fontWeight: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed'
    },
    stickyBottomCartActive: {
        backgroundColor: '#62b400',
    },
    stickyBottomCartUnActive: {
        backgroundColor: '#a8a8a8',
        opacity: 1
    },
    badgeStyle: {
        float: 'left',
        borderRadius: '100%',
        backgroundColor: '#fff',
        fontFamily: 'Roboto',
        fontWeight: 300,
        color: '#000',
        padding: '14px',
        width: 44,
        display: 'flex',
        justifyContent: 'center'
    },
    formSectionsWrapper: {
        // flexGrow: 1
    },
    menuuLogo: {
        display: 'flex',
        justifyContent: 'center',
        padding: '3vh 0vh 26vh'
    },
    menuuLogoLargeImage: {
        display: 'flex',
        maxWidth: '480px',
        margin: '0 auto',
        justifyContent: 'center',
        padding: '5px 0 0',
        backgroundColor: '#fff',
        boxShadow: '0px 16px 9px 8px rgba(0,0,0,0.07)'
    },
    menuuLogoLargeImageCheckout: {
        display: 'flex',
        maxWidth: '100%',
        margin: '0 auto',
        justifyContent: 'center',
        padding: '5px 0 0',
        backgroundColor: '#fff',
        boxShadow: '0px 16px 9px 8px rgba(0,0,0,0.02)'
    },
    productsCol: {
        borderTop: '1px solid #ced4da',
        minHeight: 35,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '0 15px',
        flex: 1
    },
    productsColCheckout: {
        borderTop: '1px solid #ced4da',
        minHeight: 35,
        padding: '0 15px',
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 1
    },
    placeOrderColumn: {
        borderTop: '1px solid rgb(206, 212, 218)',
        // height: '32%',
        padding: '0 20px'
    },
    firstCol: {
        padding: '10px 0 0 0',
        display: 'flex',
        flexDirection: 'row'
    },
    productsFirstCol: {
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'row'
    },
    placeoOrderSecondCol: {
        padding: '4px 0 0 0',
        marginBottom: 12
    },
    flexStartCol: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: 0
    },
    flexEndCol: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        padding: 0,
        minWidth: 50
    },
    startColParagraph: {
        color: '#a7a7a7',
        marginBottom: 2,
        fontSize: 15
    },
    totalStyle: {
        fontSize: 16,
        marginBottom: 0
    },
    endColParagraph: {
        alignSelf: 'flex-end',
        color: '#a7a7a7',
        fontSize: 15,
        marginBottom: 2
    },
    checkOutBtn: {
        margin: 15,
        height: 50,
        backgroundColor: '#61B522',
        border: 0,
        borderRadius: 0,
        zIndex: 1,
        flexShrink: 0
    },
    checkOutBtnDisabled: {
        margin: 15,
        height: 50,
        backgroundColor: '#a8a8a8',
        border: 'none',
        borderRadius: 0,
        pointerEvents: 'none',
        flexShrink: 0,
        opacity: 1,
        zIndex: 5
    },
    totalValue: {
        alignSelf: 'flex-end',
        fontSize: 16,
        color: '#61B522',
        marginBottom: 0
    },
    promoCodesColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px 20px',
        boxShadow: '0px -3px 6px 1px rgba(0,0,0,0.08)'
    },
    promoCodesIconStyle: {
        height: 18,
        display: 'flex',
        alignSelf: 'center',
    },
    promoCodesInput: {
        width: '63%',
        height: 45,
        borderRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        zIndex: 1
    },
    promoCodesAddonPrepend: {
        width: '12%',
        borderBottom: '1px solid rgb(206, 212, 218)',
        borderLeft: '1px solid rgb(206, 212, 218)',
        borderTop: '1px solid rgb(206, 212, 218)',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 2,
        cursor: 'pointer'
    },
    promoCodesAddonPrependRTL: {
        width: '12%',
        borderBottom: '1px solid rgb(206, 212, 218)',
        borderRight: '1px solid rgb(206, 212, 218)',
        borderTop: '1px solid rgb(206, 212, 218)',
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingLeft: 2
    },
    promoCodesAddon: {
        width: '25%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: '1px solid rgb(206, 212, 218)',
        borderLeftWidth: 0
    },
    promoCodesAddonRTL: {
        width: '25%',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        border: '1px solid rgb(206, 212, 218)',
        borderRightWidth: 0
    },
    minOrderAmount: {
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: 12,
        marginBottom: 6,
        color: '#a7a7a7'
    },
    emptyCartMsg: {
        fontSize: 14,
        color: '#a7a7a7',
        marginBottom: 4,
        textAlign: 'center'
    },
    // Cart Item Styles
    listGroupItem: {
        padding: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 0
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        border: 'none'
    },
    addRemoveItemsCol: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        bottom: 2,
        paddingRight: 0,
        paddingLeft: 0,
        minWidth: 60,
        maxWidth: 60,
        justifyContent: 'flex-start'
    },
    iconsSpanDisabled: {
        pointerEvents: 'none',
        color: '#a7a7a7'
    },
    upIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: 3,
        cursor: 'pointer',
        borderRadius: '100%',
        border: '1px solid #dcdcdc',
        margin: '0px 4px 0 0',
        padding: 2
    },
    qtyStyle: {
        display: 'flex',
        marginRight: 2
    },
    downIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        top: 3,
        borderRadius: '100%',
        border: '1px solid #dcdcdc',
        margin: '0px 4px 0 0',
        padding: 2
    },
    downIconStyleRTL: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        top: 3,
        borderRadius: '100%',
        border: '1px solid #dcdcdc',
        margin: '0 0 0 4px',
        padding: 2
    },
    itemName: {
        fontSize: 15,
        lineHeight: '20px',
        position: 'relative',
        top: '-1px'
    },
    mealDealProductName: {
        fontSize: 14,
        marginBottom: 0
    },
    itemDescription: {
        marginBottom: 0,
        fontSize: 13,
        color: '#a7a7a7'
    },
    itemNotes: {
        marginBottom: 0,
        fontSize: 13,
        color: '#a7a7a7',
        fontStyle: 'italic'
    },
    remove: {
        fontSize: 13,
        color: '#61B522',
        cursor: 'pointer',
        paddingTop: 6,
        zIndex: 10
    },
    removeDisabled: {
        fontSize: 13,
        color: '#a7a7a7',
        pointerEvents: 'none',
        paddingTop: 6,
        zIndex: 10
    },
    edit: {
        fontSize: 13,
        color: '#61B522',
        cursor: 'pointer',
        paddingLeft: 10
    },
    itemPrice: {
        fontSize: 15,
        paddingRight: 0,
        paddingLeft: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        marginTop: -2,
        maxWidth: 55
    },
    itemPriceGreen: {
        fontSize: 15,
        paddingRight: 0,
        paddingLeft: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        color: '#61B522',
        marginTop: -2,
        maxWidth: 55
    },
    cursorPointer: {
        cursor: 'pointer',
        padding: '0 15px 0 0',
        margin: '0 0 0 10px',
        width: `calc(100% - 115px)`
    },
    // END - Cart Item
    innerColPadd: {
        padding: 0
    },
    errorMsg: {
        marginBottom: 0,
        fontSize: 12,
        color: 'red'
    },
    serviceNote: {
        fontSize: 14,
        color: 'rgb(110, 118, 118)',
        padding: '0 15px',
        overflow: 'hidden auto',
        maxHeight: 105
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
};