import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import { GridLoader } from "react-spinners";
import i18n from '../../i18next';
import { isMobile } from 'mobile-device-detect';
import ModalStep from '../common/ModalStep';
import { ARABIC } from '../../utils/Constants';
import { startOrderScreenPath, resetErrMsg, stopLoader } from '../../actions';
import UserIconSVG from '../../pwa-app/components/common/svgComponents/UserIconSVG';
import FacebookLogoSVG from '../../pwa-app/components/common/svgComponents/FacebookLogoSVG';
import AppleLogoSVG from '../../pwa-app/components/common/svgComponents/AppleLogoSVG';
import GoogleLogoSVG from '../../pwa-app/components/common/svgComponents/GoogleLogoSVG';
import EmailIconSVG from '../../pwa-app/components/common/svgComponents/EmailIconSVG';
import GoogleSignInAuth from './social/GoogleSignInAuth';
import FacebookSignInAuth from './social/FacebookSignInAuth';
import AppleSignInAuth from './social/AppleSignInAuth';

class LoginTypesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCheckoutLogin: false,
            isOrderDone: false
        };

        this.goBack = this.goBack.bind(this);
        this.handlePhoneNumberButton = this.handlePhoneNumberButton.bind(this);
    }

    goBack() {
        this.props.history.push('/start-order/order-type');
    }

    componentWillMount() {
        this.applyTheme();
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    componentDidMount() {
        document.getElementsByClassName('modal-header')[0].remove();

        const isMobileView = window.outerWidth <= 800;
        if (isMobileView) {
            document.getElementsByClassName('modal-body')[0].style.minHeight = '480px';
        }

        if (this.props.isCheckoutLogin) {
            this.setState({
                isCheckoutLogin: true
            });        
        }

        if (this.props.isOrderDone) {
          this.setState({
            isOrderDone: true
          });
        }
    }

    handlePhoneNumberButton() {
        if (this.state.isCheckoutLogin) {
            const path = '/menu-screen/phone-number';
            this.props.startOrderScreenPath(path);
        } else {
            this.props.history.push('/start-order/phone-number');        	
        }
    }

    openTermsAndConditionsPage() {
        let termsAndConditionsPage = window.open('https://menuu.com/terms-of-service', '_blank');
        termsAndConditionsPage.focus();
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.transparentLoader && !this.props.isCheckoutLogin) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderModalHeader() {
        const { headerColumn, imageStyle, imageStyleRTL, loginTextStyle, loginTextStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;
        const { isOrderDone } = this.state;

        let isHidden = false;
        if (this.props.hideBackButton) {
            isHidden = true;
        }

        // Render the close button for start order modal on this screen when opening it from menuScreen
        let closeButtonShowClass = '';
        if (isOrderDone) closeButtonShowClass = ' from-menu-screen';

        return (
            <Fragment>
                <div className='start-order-header-container'>
                    <Button id="phoneScreenBackBtn" className="start-order-custom-back-button" disabled={isHidden} onClick={() => this.goBack()} style={(!isHidden) ? headerColumn : { marginRight: 10, height: '0 !important' }}>
                        <span hidden={isHidden} className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span hidden={isHidden} style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    <div style={{ margin: '0 auto', fontSize: 20, textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                        <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                        <span style={isArabic ? loginTextStyleRTL : loginTextStyle}>{i18n.t('screens:phoneNumberScreen.login')}</span>
                    </div>
                    <div style={{ width: 90, textAlign: isArabic ? 'left' : 'right' }}>
                        <Button className={`start-order-custom-close-button ${closeButtonShowClass}`} onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderModalBody() {
        const { type, primaryColor, secondaryColor, menuItemsBackground, fontFamilyType, fontBold, fontItalic, uppercase, buttonOutlineColor } = this.props.customerThemes.selectedTheme;
        const { socialButtonWrapper, subMsg, termsAndConditions, loginTextStyle, loginImageIcon, loginImageIconRTL } = styles;
        const isArabic = this.props.language === ARABIC;
        const { isCheckoutLogin, isOrderDone } = this.state;
        let modalBodyOpacity = 1;

        if (this.props.loading.transparentLoader) {
            modalBodyOpacity = 0.4;
        }

        const fontWeight = fontBold === 'bold' ? fontBold : 300;

        const loginButtonStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            maxWidth: 400,
            fontWeight: 400,
            position: 'relative',
            marginBottom: '14px',
            overflow: 'hidden',
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        
        return (
            <React.Fragment>
                {this.renderModalHeader()}
                <div className='modal-body-custom' style={{ padding: '0 30px', margin: 0, justifyContent: 'center', alignItems: 'center', opacity: modalBodyOpacity }}>
                    <Button className='loginButton loginBtnHover' color="outline-success" style={loginButtonStyle}>
                        <div style={Object.assign({}, socialButtonWrapper, {transform: 'scale(1, 1.3)', transformOrigin: 'top'})}>
                            <GoogleSignInAuth isCheckoutLogin={isCheckoutLogin} isOrderDone={isOrderDone} isPWA={false} />
                        </div>
                        <GoogleLogoSVG width={20} height={20} style={isArabic ? loginImageIconRTL : loginImageIcon} />
                        <span>{i18n.t('screens:loginTypesScreen.continueGoogle')}</span>
                    </Button>
                    <FacebookSignInAuth isCheckoutLogin={isCheckoutLogin} isOrderDone={isOrderDone} isPWA={false} />
                    <Button className='loginButton loginBtnHover' color="outline-success" style={loginButtonStyle} >
                        <div style={Object.assign({}, socialButtonWrapper, { transform: 'scale(1.07, 1)', transformOrigin: 'left' })}>
                            <AppleSignInAuth isCheckoutLogin={isCheckoutLogin} isOrderDone={isOrderDone} isPWA={false} />
                        </div>
                        <AppleLogoSVG width={26} height={26} fill='#000000' style={isArabic ? loginImageIconRTL : loginImageIcon} />
                        <span>{i18n.t('screens:loginTypesScreen.continueApple')}</span>
                    </Button>

                    <p style={Object.assign({}, subMsg, { color: primaryColor, fontWeight: 700, maxWidth: 360 })} className="termsAndCondMsg">{i18n.t('screens:loginTypesScreen.appleLoginWarning')}</p>
 
                    <div className='loginButton' style={{ position: 'relative', maxWidth: 400, margin: '12px 0' }}>
                        <span style={{ position: 'relative', padding: '0 10px', zIndex: 999, color: primaryColor, backgroundColor: menuItemsBackground }}>{i18n.t('screens:selectOrderTypeScreen.or')}</span>
                        <div style={{ width: '100%', height: '1px', position: 'absolute', top: '50%', left: 0, backgroundColor: buttonOutlineColor}}></div>
                    </div>

                    <Button className='loginButton loginBtnHover' color="outline-success" style={loginButtonStyle} onClick={this.handlePhoneNumberButton}>
                        <EmailIconSVG width={22} height={22} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? loginImageIconRTL : loginImageIcon} />
                        <span>{i18n.t('screens:loginTypesScreen.continueEmail')}</span>
                    </Button>

                    <p style={subMsg} className="termsAndCondMsg">{i18n.t('screens:loginTypesScreen.footerTermsAndCondTxt')}
                        <span style={Object.assign({}, termsAndConditions, { color: primaryColor })} onClick={() => this.openTermsAndConditionsPage()}> {i18n.t('screens:loginTypesScreen.termsAndCs')}</span>
                    </p>
                </div>
            </React.Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, fontWeight, startOrderFontSize, mainTextColor, chefNoteBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.input.fontWeight = fontWeight;
        customizedStyles.submitBtn.backgroundColor = primaryColor;
        customizedStyles.subMsg.color = mainTextColor;
        customizedStyles.termsAndConditions.color = primaryColor;
        customizedStyles.termsAndConditions.textDecoration = textDecoration;
        customizedStyles.input.backgroundColor = chefNoteBackground;
        customizedStyles.loginTextStyle.color = primaryFontColor;
        customizedStyles.loginTextStyleRTL.color = primaryFontColor;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const startOrderStyles = isMobile ? { overflowY: 'auto', overflowX: 'hidden', height: window.innerHeight } : {}
        
        return (
            <React.Fragment>
                <AvForm className="start-order phoneNumberScreen" style={startOrderStyles}>
                    <ModalStep
                        onToggle={this.props.toggle}
                        renderBody={this.renderModalBody()}
                        styles={styles}
                        customerThemes={this.props.customerThemes}
                    />
                </AvForm>
                {this.renderTransparentBackgroundLoaderContainer()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language,
        loading: state.loading
    };
};

export default connect(mapStateToProps, {
  startOrderScreenPath,
  resetErrMsg,
  stopLoader
})(LoginTypesScreen);

let styles = {
    socialButtonWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.01,
        zIndex: 9999
    },
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        color: '#939393',
        paddingBottom: 12,
        margin: 'auto'
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    loginTextStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle'
    },
    loginImageIcon: {
        marginRight: 12
    },
    loginImageIconRTL: {
        marginLeft: 12
    },
    input: {
        height: 50,
        backgroundColor: '#EFEFEF',
        textAlign: 'center'
    },
    submitBtn: {
        marginBottom: 12,
        height: 50,
        borderRadius: 0,
        minWidth: '100%',
        backgroundColor: '#61B522',
        border: 'none'
    },
    submitBtnDisabled: {
        marginBottom: 12,
        height: 50,
        borderRadius: 0,
        minWidth: '100%',
        backgroundColor: '#a8a8a8',
        border: 'none'
    },
    subMsg: {
        fontSize: 14,
        color: '#9a9a9a',
        marginBottom: 5
    },
    termsAndConditions: {
        color: "#61B522",
        cursor: 'pointer'
    },
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 16
    },
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },
    loadingContainerTransparent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        zIndex: 100000000
    }    
}